import { Avatar, Badge, Box, Divider, Stack, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
import { styled } from '@mui/material/styles';
import { blue, green, yellow } from '@mui/material/colors';
import { ParticipantView } from '../../views/ParticipantView';

const FirstBadge = styled(Badge)({
  '.MuiBadge-badge': {
    height: 24,
    width: 24,
    borderRadius: 60,
    backgroundColor: blue[500],
    color: 'white',
    transform: 'scale(1) translate(50%, 30%)',
  },
});

const SecondBadge = styled(Badge)({
  '.MuiBadge-badge': {
    height: 24,
    width: 24,
    borderRadius: 60,
    backgroundColor: yellow[800],
    color: 'white',
    transform: 'scale(1) translate(50%, 30%)',
  },
});

const ThirdBadge = styled(Badge)({
  '.MuiBadge-badge': {
    height: 24,
    width: 24,
    borderRadius: 60,
    backgroundColor: green[500],
    color: 'white',
    transform: 'scale(1) translate(50%, 30%)',
  },
});
const LeaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: 200,
  margin: 16,
  padding: 8,
});

interface LeaderItemProps {
  leader: {
    participant: ParticipantView;
    successfulWeeks: number;
    currentStreak: number;
  };
  rank: number;
}

const LeaderItem: React.FC<LeaderItemProps> = ({ leader, rank }) => {
  if (rank && rank === 1) {
    return (
      <LeaderBox sx={{ margin: 0 }}>
        <FirstBadge
          overlap={'circular'}
          color={'error'}
          badgeContent={rank}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          sx={{ transform: 'scale(1) translate(-50% -50%)' }}
        >
          <Avatar />
        </FirstBadge>
        <Typography variant={'subtitle2'}>{leader.participant.name}</Typography>
        <Typography variant={'caption'}>{leader.participant.email}</Typography>
        <Divider flexItem />
        <Stack direction={'row'} display={'flex'} flex={'1 1 auto'} alignItems={'center'}>
          <StarIcon />
          <Typography variant={'caption'}>{leader.successfulWeeks}</Typography>
        </Stack>
      </LeaderBox>
    );
  }
  if (rank && rank === 2) {
    return (
      <LeaderBox>
        <SecondBadge
          overlap={'circular'}
          color={'error'}
          badgeContent={rank}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          sx={{ transform: 'scale(1) translate(-50% -50%)' }}
        >
          <Avatar />
        </SecondBadge>
        <Typography variant={'subtitle2'}>{leader.participant.name}</Typography>
        <Typography variant={'caption'}>{leader.participant.email}</Typography>
        <Divider flexItem />
        <Stack direction={'row'} display={'flex'} flex={'1 1 auto'} alignItems={'center'}>
          <StarIcon />
          <Typography variant={'caption'}>{leader.successfulWeeks}</Typography>
        </Stack>
      </LeaderBox>
    );
  }
  return (
    <LeaderBox>
      <ThirdBadge
        overlap={'circular'}
        color={'error'}
        badgeContent={rank}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{ transform: 'scale(1) translate(-50% -50%)' }}
      >
        <Avatar />
      </ThirdBadge>
      <Typography variant={'subtitle2'}>{leader.participant.name}</Typography>
      <Typography variant={'caption'}>{leader.participant.email}</Typography>
      <Divider flexItem />
      <Stack direction={'row'} display={'flex'} flex={'1 1 auto'} alignItems={'center'}>
        <StarIcon />
        <Typography variant={'caption'}>{leader.successfulWeeks}</Typography>
      </Stack>
    </LeaderBox>
  );
};

export { LeaderItem };
