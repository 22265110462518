import React, { FC, Fragment, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DashboardView } from '../../views/DashboardView';
import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Skeleton,
} from '@mui/material';
import { ParticipantView } from '../../views/ParticipantView';
import { useTwilioClient } from '../../TwilioClientContext';
import { useCookies } from 'react-cookie';
import { HashUtils } from '../../utils/HashUtil';
import { ChatConversation } from '../ChatConversation';
import messageViewModel, { MessageView } from '../../views/MessageView';
import { Conversation } from '@twilio/conversations';
import { MessagesViewCreator } from '../../view-creator/MessagesViewCreator';
import logger from '../../utils/logger';
import { DebugPanel } from '../DebugPanel';
import { palette } from '../../palette';
import { useLocation, useNavigate } from 'react-router-dom';
import { PeopleAltTwoTone } from '@mui/icons-material';
import { NotificationService, NotificationType } from '@stores';

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: 300,
  border: '1px solid',
  borderColor: palette.primary.main,
  borderRadius: 16,
});

interface TeamCardProps {
  view: DashboardView;
  isLoading: boolean;
}

type TeamConversations = { [uniqueName: string]: Conversation };
export const TeamCard: FC<TeamCardProps> = ({ isLoading, view }: TeamCardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { client, loading } = useTwilioClient();
  const [cookies] = useCookies(['profile']);
  const [conversationModalOpen, setConversationModalOpen] = useState<boolean>(false);
  const [conversationView, setConversationView] = useState<MessageView[]>([messageViewModel.Empty]);
  const [conversation, setConversation] = useState<Conversation | undefined | null>();
  const teamConversationSid = view.groupChatId;
  const [teamConversations, setTeamConversations] = useState<TeamConversations>({});
  const [teamGroupChat, setTeamGroupChat] = useState<Conversation>();
  const [teamGroupChatLoaded, setTeamGroupChatLoaded] = useState<boolean>(false);
  const [newMsg, setNewMsg] = useState<string>('');
  const [isClientLoading, setIsClientLoading] = useState<boolean>(loading);
  const [selectedParticipant, setSelectedParticipant] = useState<ParticipantView>();
  const [loadMessages, setLoadMessages] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<{
    [participantId: number]: number | null;
  }>({});
  const [unreadTeamMessages, setUnreadTeamMessages] = useState<number>(0);
  const [initialized, setInitialized] = useState<boolean>(false);

  const initializeConversations = async () => {
    // Ensure the Twilio client is ready
    if (loading || !client || !teamConversationSid || teamConversationSid === undefined) {
      // Client is not ready
      return;
    }

    // Initialize the conversations for the group
    const teamConversation: Conversation | undefined = await client.getConversationBySid(teamConversationSid);

    // Save the conversation in state
    setTeamGroupChat(teamConversation);
    setTeamGroupChatLoaded(true);

    // When a message is added to the team chat, update the unread message count
    teamConversation.on('messageAdded', () => {
      loadTeamUnReadMessageCount(teamConversation).catch(logger.error);
    });

    // Initialize the conversations for each participant
    for (const participant of view.team.participants) {
      const uniqueName = await createFriendlyName(participant);
      if (participant.email === cookies.profile.email) {
        continue;
      }
      let dm: Conversation | null = null;

      // Get the conversation by unique name
      try {
        dm = await client.getConversationByUniqueName(uniqueName);
        if (dm && dm.status === 'notParticipating') {
          await dm.add(cookies.profile.email);
        }
      } catch (e) {
        logger.error('Got an error getting conversation: ', uniqueName, '..... error: ', e);
      }
      if (!dm) {
        // Create the conversation if it doesn't exist
        dm = await client.createConversation({
          friendlyName: uniqueName,
          uniqueName: uniqueName,
        });
        await Promise.all([dm?.add(participant.email), dm?.add(cookies.profile.email)]);
      }

      // When a message is added to a DM, update the unread message count
      dm.on('messageAdded', () => {
        loadUnReadMessageCount(dm, participant, uniqueName).catch(logger.error);
      });

      // Save the conversation in the collection
      teamConversations[uniqueName] = dm;
      setTeamConversations({ ...teamConversations });
    }

    setInitialized(true);
  };

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        await initializeConversations();
      } catch (error) {
        logger.error(error);
      }
    };

    // Ensure the Twilio client is ready and we have participants
    if (client && view?.team?.participants) {
      fetchConversations();
    }
  }, [view?.team?.participants, loading]);

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      try {
        await loadAllUnreadMessageCount();
      } catch (error) {
        logger.error(error);
      }
    };

    // Ensure the Twilio client is ready, we have participants, and we have a team chat
    if (client && view?.team?.participants && teamGroupChatLoaded && initialized) {
      fetchUnreadMessages();
    }
  }, [view?.team?.participants, loading, teamGroupChatLoaded, initialized]);

  useEffect(() => {
    logger.debug('loading state changed:', loading);
    setIsClientLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (isClientLoading) {
      logger.debug('still loading the client...setting reload to false');
      setLoadMessages(false);
      return;
    }
    if (loadMessages && initialized) {
      getMessages();
    }
  }, [isClientLoading, loadMessages]);

  useEffect(() => {
    const hash: string = location.hash;
    if (hash.startsWith('#message/')) {
      const id = hash.split('/')[1]; // Extract participantId from the hash
      const item = view.team.participants.find((p) => p.id === Number(id));
      if (item) {
        loadConversation(item);
        setConversationModalOpen(true);
      }
    } else if (hash.startsWith('#team-message')) {
      // Load team conversation
      loadTeamConversation();

      // Open the conversation modal
      setConversationModalOpen(true);
    }
  }, [location.hash, view]);

  const loadAllUnreadMessageCount = async () => {
    if (client) {
      // Load unread messages for team DMs
      for (const participant of view.team.participants) {
        if (participant.email === cookies.profile.email) {
          continue;
        }
        const uniqueName = await createFriendlyName(participant);
        const dm = teamConversations[uniqueName];

        await loadUnReadMessageCount(dm, participant, uniqueName);
      }

      // Load unread messages for the team chat
      await loadTeamUnReadMessageCount(teamGroupChat);
    }
  };

  const calculateUnreadMessagesForNullCount = async (uniqueName: string) => {
    // Get the conversation by unique name
    const thisConversation = teamConversations[uniqueName];

    // Get the last 15 messages in the conversation
    const thisConversationMessages = await thisConversation.getMessages(15);

    // The unread message count is the number of messages that were not authored by the current user
    // We know this because the unread count returned by Twilio is null, so the user has not engaged with the DM yet. Once they engage, Twilio will return a count instead of null.
    const unreadMessageCount = thisConversationMessages.items.filter(
      (message) => message.author !== cookies.profile.email
    ).length;

    // Return the unread message count so the caller can update the unread messages state
    return unreadMessageCount;
  };

  const loadUnReadMessageCount = async (
    dm: Conversation | undefined,
    participant: ParticipantView,
    uniqueName: string
  ) => {
    if (dm) {
      let unreadMessageCount: number | null | void = null;

      unreadMessageCount = await dm.getUnreadMessagesCount().catch(logger.error);

      if (unreadMessageCount) {
        setUnreadMessages(() => {
          unreadMessages[participant.id] = unreadMessageCount;
          return { ...unreadMessages };
        });
      } else if (unreadMessageCount === null) {
        // Twilio returns null for unread message count when the user has not engaged with the DM yet
        const calculatedUnreadMessageCount = await calculateUnreadMessagesForNullCount(uniqueName);
        setUnreadMessages(() => {
          unreadMessages[participant.id] = calculatedUnreadMessageCount;
          return { ...unreadMessages };
        });
      }
    }
  };

  const loadTeamUnReadMessageCount = async (teamConversation: Conversation | undefined) => {
    if (teamConversation) {
      const unreadMessageCount = await teamConversation.getUnreadMessagesCount().catch(logger.error);

      if (unreadMessageCount) {
        setUnreadTeamMessages(unreadMessageCount);
      }
    }
  };

  const getMessages = async () => {
    try {
      if (conversation) {
        logger.debug('Conversation loaded:', conversation);

        if (loadMessages) {
          const messages = await conversation.getMessages(15);
          const view = new MessagesViewCreator().createViewWithArg({
            items: messages.items,
            email: cookies.profile.email,
          });
          setConversationView(view);
        }
      }
    } catch (e) {
      logger.error('Error loadMessages -->', e);
    } finally {
      setLoadMessages(false);
    }
  };

  async function createFriendlyName(participant: ParticipantView) {
    const chatParticipants = [view.cohortUserId, participant.CohortUser.id].sort((a, b) => a - b).join('+');
    const friendlyName = await HashUtils.sha256Hash(chatParticipants);
    return friendlyName;
  }

  const loadConversation = async (participant: ParticipantView) => {
    setSelectedParticipant(participant);
    setConversationView([]);
    try {
      if (!client) {
        logger.debug('Client is not ready yet');
        return;
      }
      const friendlyName = await createFriendlyName(participant);
      const convo = teamConversations[friendlyName];
      try {
        if (convo && convo.status === 'notParticipating') {
          // Ensure the email is always lowercase - Twilio treats uppercase and lowercase as different users
          await convo.add(cookies.profile.email.toLowerCase());
        }
      } catch {
        // swallow error, likely conversation needs to be created
      }
      teamConversations[friendlyName] = convo;
      setTeamConversations({ ...teamConversations });
      convo.on('messageAdded', () => {
        setLoadMessages(true);
      });
      setConversation(convo);
      setLoadMessages(true);
      await convo.setAllMessagesRead();
      setUnreadMessages(() => {
        if (selectedParticipant) unreadMessages[selectedParticipant.id] = 0;
        return { ...unreadMessages };
      });
    } catch (error) {
      logger.error('Error loading conversation or messages:', error);
    } finally {
      logger.debug('Setting reload to false after loading conversation');
    }
  };

  const loadTeamConversation = async () => {
    setConversationView([]);
    try {
      if (!client || !teamGroupChat) {
        logger.debug('Client is not ready yet');
        return;
      }

      teamGroupChat.on('messageAdded', () => {
        setLoadMessages(true);
      });
      setConversation(teamGroupChat);
      setLoadMessages(true);
      await teamGroupChat.setAllMessagesRead();
      setUnreadTeamMessages(0);
    } catch (error) {
      logger.error('Error loading conversation or messages:', error);
    } finally {
      logger.debug('Setting reload to false after loading conversation');
    }
  };

  const handleSend: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      logger.debug('sending message changed...', newMsg);
      logger.debug('convo -->', conversation);
      const isTeamChat = !selectedParticipant;

      if (!conversation) {
        logger.warn('Convo is null, cannot send message');
        return;
      }
      if (newMsg && newMsg.length > 0) {
        const body = newMsg;
        const idx = await conversation?.sendMessage(body);
        await conversation.updateLastReadMessageIndex(idx);
        const unread = await conversation.getUnreadMessagesCount();
        setUnreadMessages((x) => {
          if (selectedParticipant) {
            x[selectedParticipant.id] = unread;
          }
          return { ...x };
        });
        setNewMsg('');
        if (isTeamChat) {
          await new NotificationService().sendNotification({
            groupId: view.groupId,
            message: `A new message was sent in your team chat! Message: ${newMsg}. Click here to read it and respond: https://wheapp.com/groups/${view.groupId}`,
            groupName: view.groupName,
            title: 'New We Hate Exercise Message',
            userId: cookies.profile.id,
            notificationType: NotificationType.TEAM_CHAT,
          });
        } else {
          await new NotificationService().sendNotification({
            groupId: view.groupId,
            message: `You have a new direct message! Message: ${newMsg}. Click here to read it and respond: https://wheapp.com/groups/${view.groupId}`,
            groupName: view.groupName,
            title: 'New We Hate Exercise Message',
            userId: cookies.profile.id,
            notificationType: NotificationType.DIRECT_MESSAGE,
            recipientId: selectedParticipant?.CohortUser.userId,
          });
        }
      }
    } finally {
      setLoadMessages(true);
    }
  };
  const handleMessageChange = (e: any) => {
    setNewMsg(e.currentTarget.value);
  };
  const handleModalClose = () => {
    // Clear unread message indicator for the selected conversation (individual or team)
    if (conversation && selectedParticipant) {
      setUnreadMessages([selectedParticipant.id]);
    } else if (conversation && teamGroupChat) {
      setUnreadTeamMessages(0);
    }

    // Close the conversation modal
    setConversationModalOpen(false);
    navigate('', { relative: 'path' });

    // Clear the selected participant and conversation
    setSelectedParticipant(undefined);
    setConversation(null);
  };

  return (
    <Fragment>
      <StyledCard>
        <CardContent>
          <Typography variant="h5" component={'div'} color={palette.primary.main}>
            Chat Your Haters
          </Typography>
          <Grid display={'flex'} justifyContent={'space-around'} container>
            {isLoading && !initialized && (
              <>
                <CircularProgress variant={'indeterminate'}></CircularProgress>
                <Grid display={'flex'} maxWidth={'100%'} flexBasis={'100%'} size={{ xs: 6, sm: 12, md: 12, lg: 6 }}>
                  <Button sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Typography component={'span'}>
                      <Skeleton variant="text" width={150} />
                      <Skeleton variant="text" width={150} />
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
            {!isLoading && initialized && (
              <Grid
                container
                flexBasis={'100%'}
                alignItems={'flex-start'}
                justifyContent={'center'}
                flexWrap={'wrap'}
                key={teamConversationSid}
                flexDirection={'row'}
                size={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <section style={{ display: 'flex', flex: '1 100%' }}>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                    <Button onClick={() => navigate('#team-message')}>
                      <Grid size={{ xs: 12 }} spacing={1} container>
                        <Badge
                          badgeContent={unreadTeamMessages}
                          color="primary"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <PeopleAltTwoTone fontSize="large" />
                        </Badge>
                        <Typography alignSelf={'center'} component={'span'} noWrap sx={{ textTransform: 'none' }}>
                          {view.groupName} | Team Chat
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                </section>
                <section style={{ display: 'flex', flex: '1 100%' }}>
                  <Grid container flexDirection={'row'} style={{ width: '100%' }}>
                    {view.team.participants
                      .filter((participant) => Boolean(participant.name))
                      .map((participant) => (
                        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={participant.id}>
                          {!participant.name && (
                            <Button>
                              <Skeleton variant="circular" width={40} height={40} />
                              <Typography component={'span'}>
                                <Skeleton variant="text" width={150} />
                                <Skeleton variant="text" width={150} />
                              </Typography>
                            </Button>
                          )}
                          {participant.name && (
                            <Button onClick={() => navigate(`#message/${participant.id}`)}>
                              <Badge
                                badgeContent={unreadMessages[participant.id]}
                                color="primary"
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <Avatar sx={{ marginRight: 1 }} alt={participant.name} src={participant.avatarUrl} />
                              </Badge>
                              <Typography component={'span'} sx={{ textTransform: 'none' }}>
                                {participant.name}
                              </Typography>
                            </Button>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </section>
                <section style={{ display: 'flex', flex: '1 1 100%' }}>
                  {view.team.participants.some((participant) => !Boolean(participant.name)) && (
                    <Typography variant={'body1'} color={'primary'}>
                      {view.team.participants.filter((participant) => !Boolean(participant.name)).length} more haters
                      are on your team and will show up for messaging after their first login. Keep checking back!
                    </Typography>
                  )}
                </section>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </StyledCard>
      <Dialog onClose={handleModalClose} fullWidth open={conversationModalOpen}>
        <DebugPanel
          displayItem={{
            state: conversation?.state,
            status: conversation?.status,
            uniqueName: conversation?.uniqueName,
          }}
        />
        <DialogTitle>
          {selectedParticipant && (selectedParticipant.name || selectedParticipant.email)
            ? `Direct Message with ${selectedParticipant.name || selectedParticipant.email}`
            : 'Team Chat'}
        </DialogTitle>
        <DialogContent>
          <ChatConversation
            autoScroll
            unreadMessages={0}
            newMsg={newMsg}
            view={conversationView}
            onSend={handleSend}
            onMessageChange={handleMessageChange}
            cohortParticipants={view.team.participants}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
