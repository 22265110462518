// src/NotificationContext.tsx
import React, { createContext, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { SubscriptionStore } from '../api/SubscriptionStore';
import logger from '../utils/logger';

interface NotificationContextProps {
  subscription: PushSubscription | null;
}

interface NotificationProviderProps extends PropsWithChildren {
  children: ReactNode;
}

export const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [subscription, setSubscription] = useState<PushSubscription | null>(null);

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  const requestNotificationPermission = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const existingSubscription = await registration.pushManager.getSubscription();

        if (existingSubscription) {
          logger.log('User is already subscribed:', existingSubscription);
          setSubscription(existingSubscription);
        } else {
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(import.meta.env.VITE_APP_VAPID_PUBLIC_KEY!),
          });

          logger.log('User is subscribed:', subscription);
          await new SubscriptionStore().subscribe(subscription);
          setSubscription(subscription);
        }
      } catch (error) {
        logger.error('Failed to subscribe the user: ', error);
      }
    } else {
      logger.warn('Push messaging is not supported');
    }
  };

  return <NotificationContext.Provider value={{ subscription }}>{children}</NotificationContext.Provider>;
};

function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
