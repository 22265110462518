import { FC, PropsWithChildren } from 'react';
import { palette } from '../palette';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface GroupedBoxProps extends PropsWithChildren {
  title: string;
}

const AbsoluteDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));

export const GroupedBox: FC<GroupedBoxProps> = ({ children, title }) => {
  return (
    <AbsoluteDiv>
      <Typography
        variant={'caption'}
        sx={{
          position: 'absolute',
          backgroundColor: palette.background.paper,
          padding: 1,
          borderRadius: 4,
          left: 16,
          top: -18,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          border: 1,
          borderRadius: 5,
          padding: 2,
          borderColor: palette.background.border,
        }}
      >
        {children}
      </Box>
    </AbsoluteDiv>
  );
};
