import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useUiState } from '../utils/UiStateHook';
import { DateTime } from 'luxon';
import { ExerciseContent, ExerciseInDisguiseContentViewCreator } from '@viewCreators';
import { ToDateTime } from '../utils/DateUtils';
import DisabledCardState from './Cards/DisabledCardState';

interface ExerciseInDisguiseProps {
  enabled?: boolean;
  disabledMessage: string;
  groupStart: DateTime | string;
  triggerReload: (reload: boolean) => void;
}

export const ExerciseInDisguise: React.FC<ExerciseInDisguiseProps> = ({
  enabled = false,
  disabledMessage,
  groupStart,
  triggerReload,
}) => {
  const uiState = useUiState(enabled);
  const [content, setContent] = React.useState<ExerciseContent>({} as ExerciseContent);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    new ExerciseInDisguiseContentViewCreator()
      .CreateView(ToDateTime(groupStart))
      .then((content) => setContent(content));
  }, [groupStart]);
  return (
    <Fragment>
      <Card
        variant={'elevation'}
        sx={{
          borderRadius: 4,
          border: '1px solid',
          borderColor: uiState.borderColor,
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" color={enabled ? 'primary' : 'textDisabled'}>
            Exercise In Disguise
          </Typography>
          {!enabled && (
            <DisabledCardState
              disabledMessage={disabledMessage}
              src="/assets/svg/exercise-in-disguise.svg"
              alt="Get stoked!"
            />
          )}
          {enabled && (
            <>
              <div className="card-content-wrapper">
                <img src={'/assets/svg/exercise-in-disguise.svg'} alt={'Exercise in Disguise'} className="card-logo" />
                <Typography variant="smallCursive" component="p" className="card-copy">
                  {content.content?.title}
                </Typography>
              </div>
              <CardActions
                sx={{
                  alignItems: 'end',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'column',
                }}
              >
                <Button
                  disabled={!enabled && content.content}
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(true)}
                >
                  Learn More
                </Button>
              </CardActions>
            </>
          )}
        </CardContent>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{content.content?.title || ''}</DialogTitle>
        <DialogContent>
          <Typography dangerouslySetInnerHTML={{ __html: content.content?.body || '' }}></Typography>
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} onClick={() => setOpen(false)}>
            Got It!
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
