import { Card, CardActions, CardContent, Skeleton, Typography } from '@mui/material';
import React, { CSSProperties, Fragment, PropsWithRef } from 'react';
import { useUiState } from '../utils/UiStateHook';
import DisabledCardState from './Cards/DisabledCardState';

interface PotCardProps extends PropsWithRef<any> {
  value: string;
  cohortUserId: number;
  isLoading: boolean;
  enabled?: boolean;
  disabledMessage: string;
}

export const PotCard: React.FC<PotCardProps> = ({ enabled = false, disabledMessage, isLoading, value }) => {
  const uiState = useUiState(enabled);

  // Function to map string to value
  const backgroundStyles: CSSProperties = {
    border: '1px solid',
    borderColor: uiState.backgroundColor,
    borderRadius: 16,
  };

  return (
    <Fragment>
      {isLoading && (
        <Card style={backgroundStyles} variant={'elevation'}>
          <CardContent>
            <Typography variant={'h4'} component={'p'}>
              <Skeleton width="80%" />
            </Typography>
            <Typography variant={'h3'} component={'p'}>
              <Skeleton width="60%" />
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'end', height: '90%' }}>
            <Skeleton variant="rectangular" width={100} height={40} />
          </CardActions>
        </Card>
      )}
      {!isLoading && (
        <Card style={backgroundStyles} variant={'elevation'}>
          <CardContent>
            <Typography variant="h5" component="div" color={enabled ? 'primary' : 'textDisabled'}>
              Pledge Pot
            </Typography>
            {!enabled && (
              <DisabledCardState disabledMessage={disabledMessage} src="/assets/svg/pot.svg" alt="Get stoked!" />
            )}
            {enabled && (
              <div className="card-content-wrapper">
                <img src={'/assets/svg/pot.svg'} alt={'Pot'} className="card-logo" />
                <Typography variant={'smallCursive'} component={'p'}>
                  {value}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
};
