// src/components/UserRoleProvider.tsx

import React, { createContext, ReactNode, useEffect } from 'react';
import { UserStore } from '@stores';
import apiResponse from '../../api/ApiResponse';
import { roleMap } from '../../utils/RoleUtils';
import { useCookies } from 'react-cookie';

// Create a type for user roles
type UserRole = number;

// Create a context for the user's roles
const UserRoleContext = createContext<UserRole>(0);

interface UserRoleProviderProps {
  children: ReactNode;
}

// Provider component to wrap the application or part of it
export const UserRoleProvider: React.FC<UserRoleProviderProps> = ({ children }) => {
  const [cookies] = useCookies(['profile']);
  const [userRole, setUserRole] = React.useState<UserRole>(0);
  useEffect(() => {
    if (cookies.profile) {
      const response = new UserStore().getUserRole(cookies.profile.email);
      apiResponse
        .handleApiResponse(() => response)
        .then((role) => {
          setUserRole(roleMap(role.split('|')));
        });
    }
  }, [cookies.profile]);

  return <UserRoleContext.Provider value={userRole}>{children}</UserRoleContext.Provider>;
};

export { UserRoleContext };
