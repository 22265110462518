import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { FC } from 'react';
import { useCookies } from 'react-cookie';

interface DebugPanelProps {
  displayItem: any;
}

export const DebugPanel: FC<DebugPanelProps> = ({ displayItem }) => {
  const [cookies] = useCookies(['auth', 'profile']);
  if (import.meta.env.VITE_APP_ENV !== 'development') return null;
  return (
    <Accordion>
      <AccordionSummary>Debug</AccordionSummary>
      <AccordionDetails>
        <pre>{JSON.stringify(displayItem, null, 2)}</pre>
      </AccordionDetails>
    </Accordion>
  );
};
