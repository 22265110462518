import React from 'react';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { LeaderView } from '@views';
import { useUiState } from '@utils/UiStateHook';
import DisabledCardState from '@components/Cards/DisabledCardState';
import './Leaderboard.scss';

interface LeaderboardProps {
  leaders: LeaderView;
  enabled?: boolean;
  disabledMessage: string;
}

const LeaderCard: React.FC<LeaderboardProps> = ({ enabled = false, disabledMessage, leaders }) => {
  const uiState = useUiState(enabled);
  return (
    <Card
      variant={'elevation'}
      sx={{
        border: '1px solid',
        borderRadius: 4,
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" color={enabled ? 'primary' : 'textDisabled'}>
          Top Haters
        </Typography>
        {!enabled && (
          <DisabledCardState disabledMessage={disabledMessage} src="/assets/svg/top-haters.svg" alt="Get stoked!" />
        )}

        {enabled && (
          <div className="card-content-wrapper">
            <img src="/assets/svg/top-haters.svg" alt="Top Haters" className="card-logo" />
            <List className="leaderboard-list">
              {Object.keys(leaders).map((rank) => {
                const leader = leaders[Number(rank)];
                return (
                  <ListItem key={leader.participant.id}>
                    <ListItemAvatar>
                      <Avatar alt={leader.participant.name} src={leader.participant.avatarUrl} />
                    </ListItemAvatar>
                    <ListItemText primary={leader.participant.name} />
                    <Typography variant="cursive" sx={{ marginLeft: '10px', fontSize: '18px' }}>
                      {leader.successfulWeeks}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
            <Typography className="leaderboard-note" color="primary">
              Note: The number next to each name shows how many weeks that hater was successful in meeting their
              exercise goal. Amazing work, everyone!
            </Typography>
          </div>
        )}
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export { LeaderCard };
