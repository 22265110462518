import { DebugPanel } from '../DebugPanel';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { CreateGoalForm } from '@components';
import { AddCircleOutline, ArrowDownward, ArrowUpward, AssignmentTurnedIn, CancelOutlined } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { GoalView, RawGoal, valueToActivityString, valueToString } from '@views';
import { GroupedBox } from '@components/GroupedBox';
import { UpdateGoalsDialogConfirmation } from './UpdateGoalsDialog.confirmation';
import { DateTime } from 'luxon';
import { GoalFormValidator } from '@app/validators/GoalFormValidator';

const defaultFormValues = {
  activityId: -1,
  duration: 0,
};
const defaultView = {
  name: '',
  activityId: -1,
  duration: 0,
  durationTimeframe: 2,
  frequency: 1,
  timeframe: 1,
  startDate: DateTime.local().startOf('week'),
  endDate: DateTime.local().startOf('week').plus({ weeks: 4 }).minus({ days: 1 }),
  datesEnabled: true,
  alternate: '',
};

export interface UpdateGoalsDialogProps {
  showNewGoalDialog: boolean;
  submitGoals: (e: any, reason: 'SAVE' | 'CANCEL', goals: RawGoal[]) => Promise<void>;
  cohortUserId: number;
}

export const UpdateGoalsDialog: React.FC<UpdateGoalsDialogProps> = ({
  showNewGoalDialog,
  submitGoals,
  cohortUserId,
}) => {
  const rawGoalTemplate = {
    ...defaultView,
    cohortUserId: cohortUserId,
    durationTimeframeId: defaultView.durationTimeframe,
    timeframeId: defaultView.timeframe,
  };
  const [newGoal, setNewGoal] = useState<GoalView>(rawGoalTemplate);
  const validator = new GoalFormValidator();
  const bottomRef = useRef(null as any);
  const topRef = useRef(null as any);
  const [newGoalNames, setNewGoalNames] = useState<string[]>([]);
  const [rawGoals, setRawGoals] = useState<RawGoal[]>([]);
  const [addGoalDisabled, setAddGoalDisabled] = useState<boolean>(true);
  const [saveGoalDisabled, setSaveGoalDisabled] = useState<boolean>(true);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | null>>({});
  const [passedValidation, setPassedValidation] = useState<boolean>(false);

  function buildGoalText(
    activity: number,
    frequency: number,
    timeframe: number,
    duration: number,
    durationTimeframe: number
  ) {
    return `I commit to ${valueToActivityString(activity)} ${frequency} ${valueToString(timeframe)} for ${duration} ${valueToString(durationTimeframe)}`;
  }

  const confirmNewGoals = (e: any) => {
    setConfirmationOpen(true);
  };

  const handleRemoveGoal = (e: any, idx: number) => {
    rawGoals.splice(idx, 1);
    setRawGoals([...rawGoals]);

    newGoalNames.splice(idx, 1);
    setNewGoalNames([...newGoalNames]);
  };

  const handleAddGoal = (e: any) => {
    setRawGoals([
      ...rawGoals,
      {
        ...newGoal,
        cohortUserId,
        durationTimeframeId: newGoal.durationTimeframe,
        timeframeId: newGoal.timeframe,
      } as RawGoal,
    ]);
    setNewGoalNames([...newGoalNames, newGoal.name]);
    window.setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  useEffect(() => {
    const formHasDefaults =
      newGoal.activityId === defaultFormValues.activityId || newGoal.duration === defaultFormValues.duration;

    setAddGoalDisabled(formHasDefaults || !passedValidation);
    setSaveGoalDisabled(rawGoals.length < 1 || !passedValidation);
  }, [validationErrors, rawGoals]);

  function onConfirmClose(e: any, reason: 'OK' | 'CANCEL') {
    if (reason === 'OK') {
      setNewGoalNames([...newGoalNames]);
      submitGoals(e, 'SAVE', rawGoals);
    }
    setConfirmationOpen(false);
  }

  const updateAndValidateField = async (fieldName: string, value: any) => {
    const updatedView = { ...newGoal, [fieldName]: value };
    const name = buildGoalText(
      updatedView.activityId,
      updatedView.frequency,
      updatedView.timeframe,
      updatedView.duration,
      updatedView.durationTimeframe
    );

    const newView = {
      ...updatedView,
      name,
      [fieldName]: value,
    };
    // Trigger real-time validation
    const { errors, success } = validator.validateView(newView, { validateField: fieldName });
    setNewGoal(newView);
    setPassedValidation(success || false);
    setValidationErrors((prev) => ({
      ...prev,
      [fieldName]: errors[fieldName],
    }));
  };

  return (
    <Dialog
      open={showNewGoalDialog}
      fullScreen
      onClose={(e: any) => submitGoals(e, 'CANCEL', [])}
      slotProps={{
        backdrop: { style: { backgroundColor: 'background.paper' } },
      }}
    >
      <DebugPanel displayItem={{ view: newGoal, errors: validationErrors, rawGoals }}></DebugPanel>
      <DialogTitle>Set Your SMART Goal </DialogTitle>
      <Divider></Divider>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          overflow: 'scroll',
        }}
      >
        <div ref={topRef}></div>
        <Typography>Each week I will...</Typography>
        <GroupedBox title={'Activities'}>
          <CreateGoalForm formState={newGoal} onChange={updateAndValidateField} validationErrors={validationErrors} />

          <Typography>
            {newGoalNames.length >= 3
              ? 'You have reached the maximum of 3 activities in your goal. Review all activities before clicking "Save".'
              : 'Click "Add Activity" below after each entry. You can include one, two or three activities in your goal. Scroll down to review all activities in your goal before clicking "Save."'}
          </Typography>
        </GroupedBox>
        {/* Only allow users to add up to 3 goals */}
        {newGoalNames.length < 3 && (
          <Button
            variant={'outlined'}
            sx={{ alignSelf: 'center' }}
            color={'primary'}
            size={'small'}
            onClick={handleAddGoal}
            disabled={addGoalDisabled}
          >
            <AddCircleOutline sx={{ paddingX: 1 }} />
            Add Activity
            <ArrowDownward sx={{ paddingX: 1 }} />
          </Button>
        )}
        {newGoalNames.length > 0 && (
          <GroupedBox title={'New Activities'}>
            <List>
              {newGoalNames.map((goal, idx) => {
                return (
                  <ListItem key={`goal_${idx}`}>
                    <ListItemIcon>
                      <AssignmentTurnedIn color={'primary'} />
                    </ListItemIcon>
                    <ListItemText>{goal}</ListItemText>
                    <ListItemButton onClick={(e) => handleRemoveGoal(e, idx)}>
                      <CancelOutlined color={'primary'} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <div id={'new-goals'}>&nbsp;</div>
            <DialogActions>
              {newGoalNames.length < 3 && (
                <Button variant={'outlined'} onClick={() => topRef.current?.scrollIntoView({ behavior: 'smooth' })}>
                  <AddCircleOutline sx={{ paddingX: 1 }} />
                  Add Activity
                  <ArrowUpward sx={{ paddingX: 1 }} />
                </Button>
              )}
              <Button
                disableElevation
                color={'secondary'}
                size={'large'}
                variant={'contained'}
                onClick={(e: any) => submitGoals(e, 'CANCEL', [])}
              >
                Cancel
              </Button>
              <Button variant={'contained'} disabled={saveGoalDisabled} size={'large'} onClick={confirmNewGoals}>
                Save
              </Button>
            </DialogActions>
          </GroupedBox>
        )}
        <div ref={bottomRef}></div>
        <UpdateGoalsDialogConfirmation open={confirmationOpen} onClose={onConfirmClose} activities={newGoalNames} />
      </DialogContent>
    </Dialog>
  );
};
