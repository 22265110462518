import { DebugPanel } from '../DebugPanel';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
} from '@mui/material';
import logger from '@utils/logger';
import React, { FC } from 'react';
import { common } from '@mui/material/colors';
import { themeOptions } from '@app/palette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { AsyncEmptyFunction } from '@utils/common-types';
import { GoalWithStatus } from '@app/components';
import { GoalStatus } from '@app/enums/GoalStatus';

interface ConfirmGoalCompletionDialogProps {
  open: boolean;
  onClose: (selectedGoal: GoalWithStatus, idx: number) => void;
  selectedGoal: GoalWithStatus;
  addActivity: AsyncEmptyFunction;
}

export const ConfirmGoalCompletionDialog: FC<ConfirmGoalCompletionDialogProps> = ({
  open,
  onClose,
  selectedGoal,
  addActivity,
}) => {
  const bodyFont = (themeOptions.typography as TypographyOptions).body1!.fontFamily || 'roboto';
  const [alertOpen, setAlertOpen] = React.useState(false);
  const onConfirm = () => {
    addActivity()
      .then(() => {
        setAlertOpen(true);
      })
      .catch((err) => {
        logger.error(err);
      });
  };
  return (
    <>
      <Dialog open={open}>
        <DebugPanel displayItem={selectedGoal}></DebugPanel>
        <DialogTitle>Confirm Goal</DialogTitle>
        <DialogContent>
          <Typography>Did you complete a workout towards this part of your goal?</Typography>
          <Typography>{selectedGoal?.goal.goalText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              onClose(
                selectedGoal,
                selectedGoal.progress.findIndex((x) => x !== GoalStatus.Succeeded)
              )
            }
          >
            Cancel
          </Button>
          <Button variant={'contained'} onClick={onConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          variant={'filled'}
          sx={{
            width: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
          }}
          onClose={() => {
            setAlertOpen(false);
          }}
        >
          <AlertTitle color={common.white}>Success!</AlertTitle>
          <p style={{ color: common.white, fontFamily: bodyFont }}>WAY TO GO!</p>;
        </Alert>
      </Snackbar>
    </>
  );
};
