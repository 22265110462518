import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { palette } from '../palette';
import { Google } from '@mui/icons-material';
import React, { PropsWithChildren } from 'react';

interface LoginButtonProps extends PropsWithChildren {
  onLoginClicked?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  authenticationRedirectUrl?: string;
  loginButtonHelp: string | React.ReactElement;
}

export function LoginButton({ children, loginButtonHelp, onLoginClicked, disabled = false }: LoginButtonProps) {
  const authUrl = (import.meta.env.VITE_APP_BASE_SERVER_URL || 'http://localhost:3000') + '/auth';
  return (
    <Box height={'100vh'} display={'flex'} flexDirection={'column'} flex={'auto'} justifyContent={'center'}>
      <Grid
        container
        gap={2}
        borderRadius={4}
        padding={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-around'}
      >
        <Grid item display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <img alt="We Hate Exercise" height={112} src={'./logo.png'}></img>
        </Grid>

        <Divider sx={{ display: { xs: 'none', sm: 'block' } }} orientation={'vertical'}></Divider>

        <Grid item display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'}>
          {disabled && (
            <Tooltip title={loginButtonHelp} arrow placement={'top'}>
              <span>
                <Button href={authUrl} onClick={onLoginClicked} disabled>
                  <Grid
                    item
                    borderRadius={1}
                    padding={1}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-evenly'}
                    bgcolor={palette.secondary.dark}
                  >
                    <Google color={'disabled'} sx={{ height: '1.2em' }}></Google>
                    <Typography variant={'button'} color={palette.secondary.light} padding={'4px'}>
                      Continue with Google
                    </Typography>
                  </Grid>
                </Button>
              </span>
            </Tooltip>
          )}
          {!disabled && (
            <Tooltip title={loginButtonHelp} arrow placement={'top'}>
              <Button color={'primary'} href={authUrl} onClick={onLoginClicked}>
                <Grid
                  item
                  borderRadius={1}
                  padding={1}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-evenly'}
                  bgcolor={palette.primary.main}
                >
                  <Google color={'secondary'} sx={{ height: '1.2em' }}></Google>
                  <Typography variant={'button'} color={palette.secondary.contrastText} padding={'4px'}>
                    Continue with Google
                  </Typography>
                </Grid>
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {children}
    </Box>
  );
}
