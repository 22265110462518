import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ArrowRight } from '@mui/icons-material';

export interface UpdateGoalsDialogConfirmationProps {
  activities: string[];
  open: boolean;
  onClose: (e: any, reason: 'OK' | 'CANCEL') => void;
}

export const UpdateGoalsDialogConfirmation: FC<UpdateGoalsDialogConfirmationProps> = ({
  activities,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Here’s your goal - does this include all the activities you want?</DialogTitle>
      <DialogContent>
        {activities.map((activity, idx) => {
          return (
            <List key={`act-${idx}`}>
              <ListItem disablePadding>
                <ListItemIcon sx={{ padding: 0, minWidth: 32 }}>
                  <ArrowRight />
                </ListItemIcon>
                <ListItemText>{activity}</ListItemText>
              </ListItem>
            </List>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => onClose(e, 'CANCEL')}>Cancel</Button>
        <Button variant={'contained'} onClick={(e) => onClose(e, 'OK')}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
